* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  outline: none;
  box-sizing: border-box;
}
a {
  text-decoration: none;
}

.header-section {
  background: transparent;
  color: white;
}
.dark-nav {
  background: #2c3531;
  box-shadow: rgba(245, 240, 240, 0.2) 0px 60px 40px -7px;
}

h1.text-white.homeimage-content-heading {
  font-family: Gellix;
  font-size: 87px;
}

img#img{
  border-radius: 250px;
}
lement.style {
  text-decoration: none;
  /* color: gray; */
  color: white;
}
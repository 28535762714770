/* .contactform-section {
  position: relative;
  display: grid;
  grid-template-columns: 40% 60%;

  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  padding: 10px;

  background: linear-gradient(115deg, #56d8e4 10%, #9f01ea 90%);
}
.contactform-address {
  position: absolute;
  top: 15%;
  left: 8%;
}
.address a {
  text-decoration: none;
  color: black;
}
.contactform-container {
  position: absolute;
  top: 15%;
  right: 10%;
  background: #fff;
  max-width: 700px;

  padding: 25px 40px 10px 40px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.contactform-container .contactform-heading {
  text-align: center;
  font-size: 41px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  background: -webkit-linear-gradient(
    right,
    #56d8e4,
    #9f01ea,
    #56d8e4,
    #9f01ea
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.contactform-container form {
  padding: 30px 0 0 0;
}
.contactform-container form .form-row {
  display: flex;
  margin: 32px 0;
}
form .form-row .input-data {
  width: 100%;
  height: 40px;
  margin: 0 20px;
  position: relative;
}
form .form-row .textarea {
  height: 70px;
}
.input-data input,
.textarea textarea {
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  font-size: 17px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
}
.input-data input:focus ~ label,
.textarea textarea:focus ~ label,
.input-data input:valid ~ label,
.textarea textarea:valid ~ label {
  transform: translateY(-20px);
  font-size: 14px;
  color: #3498db;
}
.textarea textarea {
  resize: none;
  padding-top: 10px;
}
.input-data label {
  position: absolute;
  pointer-events: none;
  bottom: 10px;
  font-size: 16px;
  transition: all 0.3s ease;
}
.textarea label {
  width: 100%;
  bottom: 40px;
  background: #fff;
}
.input-data .underline {
  position: absolute;
  bottom: 0;
  height: 2px;
  width: 100%;
}
.input-data .underline:before {
  position: absolute;
  content: "";
  height: 2px;
  width: 100%;
  background: #3498db;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.3s ease;
}
.input-data input:focus ~ .underline:before,
.input-data input:valid ~ .underline:before,
.textarea textarea:focus ~ .underline:before,
.textarea textarea:valid ~ .underline:before {
  transform: scale(1);
}
.submit-btn .input-data {
  overflow: hidden;
  height: 45px !important;
  width: 25% !important;
}
.submit-btn .input-data .inner {
  height: 100%;
  width: 300%;
  position: absolute;
  left: -100%;
  background: -webkit-linear-gradient(
    right,
    #56d8e4,
    #9f01ea,
    #56d8e4,
    #9f01ea
  );
  transition: all 0.4s;
}
.submit-btn .input-data:hover .inner {
  left: 0;
}
.submit-btn .input-data input {
  background: none;
  border: none;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  position: relative;
  z-index: 2;
}
/* @media (max-width: 700px) {
  .contactform-section {
    display: grid;
    grid-template-rows: 600px 600px;
  }
  /* .contactform-address {
    position: relative; 
    top: auto;
    left: 0px;
    width: 100%; 
    text-align: center; 
  }
  .contactform-container {
    position: relative; 
    top: 400px;
    left: 0px;
    width: 100%; 
    text-align: center;
  } */
/* .contactform-container .contactform-heading {
    font-size: 30px;
  }
  .contactform-container form {
    padding: 10px 0 0 0;
  }
  .contactform-container form .form-row {
    display: block;
  }
  form .form-row .input-data {
    margin: 35px 0 !important;
  }
  .submit-btn .input-data {
    width: 40% !important;
  } 
} */
.contactform-section {
  background: linear-gradient(115deg, #56d8e4 10%, #9f01ea 90%);
}
.contactform-section a {
  text-decoration: none;
  color: black;
}

.homeimage-container {
  background-image: url(../Assets/homebanner2.jpg);
  height: 600px;
  background-size: cover;
}
.homeimage-content {
  padding-top: 100px;
}
.homeimage-content h1 {
  font-weight: 900;
  font-size: 4rem;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  /* Your tablet styles here */
}

/* Media query for mobile devices (portrait) */
@media only screen and (max-width: 767px) {
  .homeimage-container {
    height: 600px;
    background-size: cover;
  }
  .homeimage-content h1 {
    font-size: 20px;
  }
  element.style {
    font-family: gellix;
    font-size: 20px;
}
element.style {
  font-size: 20px;
  font-family: gellix;
}
}

.homeservice-container p {
  padding-left: 20%;
  padding-right: 20%;
}

.homeservice-container-col {
  height: 200px;
}
.homeservices-link {
  text-decoration: none;
  color: black;
}
.homeservice-container-img {
  width: 60%;
  padding: 0;
}
.homeservice-container-col-div:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.homeservice-container-col-div {
  width: 100%;
  height: 90%;
}

@media only screen and (max-width: 600px) {
  .homeservice-container-col {
    height: 300px;
  }
  element.style {
    font-size: 20px;
    font-family: gellix;
}
}

.testingbanner-container {
  position: relative;
}
.testingbanner-container img {
  width: 100%;
  margin: 0;
  padding: 0;
  height: 600px;
}

.testingbanner-container-content {
  position: absolute;
  top: 25%;
  left: 5%;
  max-width: 50%;
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .testingbanner-container-content {
    position: absolute;
    top: 15%;
    left: 5%;
    max-width: 70%;
  }
}

@media only screen and (max-width: 767px) {
  .testingbanner-container-content {
    position: absolute;
    top: 15%;
    left: 5%;
    max-width: 80%;
  }
}

.weoffer-container img {
  padding: 0;
  width: 50%;
  height: 50%;
}
.weoffer-container {
  height: 450px;
  overflow: hidden;
  padding-bottom: 10px;
}
.hometech-text-content {
  padding: 1rem;
  text-align: center;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 12;
  -webkit-box-orient: vertical;
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .weoffer-container {
    overflow: hidden;
    padding-bottom: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .weoffer-container {
    overflow: hidden;
    padding-bottom: 10px;
  }
}

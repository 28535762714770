.foot-col2 p {
  line-height: 2rem;
}
.footer-container p {
  color: rgb(205, 195, 195);
  text-decoration: none;
}
.footer-container small {
  color: rgb(205, 195, 195);
}
.footer-container a {
  text-decoration: none;
  color: rgb(205, 195, 195);
}

.industrybody-section {
  display: flex;
  flex-direction: column;
}

.industry-container-col1 {
  height: 400px;
}

.industry-container-col1 img {
  width: 450px;
  height: 350px;
  padding: 10px 10px;
}

.industry-img {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  /* padding: 0; */
}

.industry-container-col2 {
  height: 400px;
}

@media (min-width: 1025px) {
  .bigscreen-container {
    display: block;
  }

  .mobile-container {
    display: none;
  }
}

/* Media query for tablets and mobile devices (up to 1024px) */
@media (max-width: 1024px) {
  .bigscreen-container {
    display: none; /* Hide container 1 */
  }

  .mobile-container {
    display: block; /* Display container 2 */
  }
  .industry-container-col2 {
    height: 400px;
    overflow: hidden;
  }
  .industry-container-col1 img {
    width: 350px;
    height: 300px;
    padding: 10px 10px;
  }
}

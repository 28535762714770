.contactskills-container {
  display: grid;
  grid-template-columns: 20% 80%;
}
.contactskills-container-image img {
  padding: 0;
  width: 70%;
}
@media (max-width: 700px) {
  .contactskills-container-image {
    display: none;
  }
  .contactskills-container {
    display: grid;
    grid-template-columns: 100% 0%;
  }
}

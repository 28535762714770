.header-section {
  background: transparent;
  color: rgb(128, 124, 124);
}
.dark-nav {
  background: #2c3531;
  box-shadow: rgba(245, 240, 240, 0.2) 0px 60px 40px -7px;
}

.header-section Link {
  text-decoration: none;
  color: rgb(128, 124, 124);
}

.navbutton:hover {
  color: rgb(231, 203, 203);
}
div#imghide {
  display: none;
}

.img-1{
border-radius: 50%
}

/* Medium tablet screens */
@media (min-width: 600px) and (max-width: 767px) {
  .dropdown-item:focus, .dropdown-item:hover {
    background-color:#020705 !important;
    }
  .offcanvas{
    --bs-offcanvas-width: 60% !important; 
  }
  /* styles for large mobile screens */

  .btn-close {
    --bs-btn-close-bg: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e) !important;
  }
  /* styles for medium tablet screens */
  .dgColor{
      background: transparent  !important;
    color: white !important;
  }

  .dgColor div {
    background-color: inherit;
  }

  a:hover {
    background: #020705 !important;
  }
  
  div[data-bs-popper="static"] {
    background: #020705 !important;
  }
  
}

/* Small tablet screens */
@media (min-width: 480px) and (max-width: 599px) {
  .dropdown-item:focus, .dropdown-item:hover {
    background-color:#020705 !important;
    }
  .offcanvas{
    --bs-offcanvas-width: 60% !important; 
  }
  /* styles for large mobile screens */

  .btn-close {
    --bs-btn-close-bg: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e) !important;
  }
  /* styles for small tablet screens */
  .dgColor{
      background: transparent !important;
    color: white !important;
  }
  .dgColor div {
    background-color: inherit;
  }

  /* a:hover {
    background: #020705 !important;
  } */
  div[data-bs-popper="static"] {
    background: #020705 !important;
  }
  
}

/* Large mobile screens */
@media (min-width: 480px) and (max-width: 640px) {
  .dropdown-item:focus, .dropdown-item:hover {
    background-color:#020705 !important;
    }
  .offcanvas{
    --bs-offcanvas-width: 60% !important; 
  }
  /* styles for large mobile screens */

  .btn-close {
    --bs-btn-close-bg: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e) !important;
  }
  .dgColor{
      background: #020705 !important;
    color: white !important;
  }
  .dgColor div {
    background-color: inherit;
  }

  /* a:hover {
    background: #020705 !important;
  } */
  div[data-bs-popper="static"] {
    background: #020705 !important;
  }
  
}

/* Large mobile screens */
@media (min-width: 480px) and (max-width: 640px) {
  .dropdown-item:focus, .dropdown-item:hover {
    background-color:#020705 !important;
    }
  .offcanvas{
    --bs-offcanvas-width: 60% !important; 
  }
  /* styles for large mobile screens */
  .btn-close {
    --bs-btn-close-bg: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e) !important;
  }
  .dgColor{
      background: transparent !important;
    color: white !important;
  }
  .dgColor div {
    background-color: inherit;
  }

  /* a:hover {
    background: #020705 !important;
  } */
  .offcanvas.offcanvas-end.show {
    background-color:#020705 !important; /* light gray background */
    color: white !important;
  }

  div[data-bs-popper="static"] {
    background: #020705 !important;
  }
  
}

/* Medium mobile screens */
@media (min-width: 320px) and (max-width: 479px) {
  .dropdown-item:focus, .dropdown-item:hover {
    background-color:#020705 !important;
    }
  .offcanvas{
    --bs-offcanvas-width: 60% !important; 
  }
  .btn-close {
    --bs-btn-close-bg: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e) !important;
  }
  /* styles for medium mobile screens */
  .dgColor{
      background:transparent !important;
    color: white !important;
  }
  .dgColor div {
    background-color: inherit;
  }

  /* a:hover {
    background: #020705 !important;
  } */
  
  .offcanvas.offcanvas-end.show {
    background-color:#020705 !important; /* light gray background */
    color: white !important;
  }

  div[data-bs-popper="static"] {
    background: #020705 !important;
  }
}

/* Small mobile screens */
@media (max-width: 319px) {
  .dropdown-item:focus, .dropdown-item:hover {
    background-color:#020705 !important;
    }
  .offcanvas{
    --bs-offcanvas-width: 60% !important; 
  }

  .btn-close {
    --bs-btn-close-bg: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e) !important;
  }
  /* styles for small mobile screens */
  .dgColor{
      background: transparent !important;
    color: white !important;
  }
  .dgColor div {
    background-color: inherit;
  }

  /* a:hover {
    background: #020705 !important;
  } */
  .offcanvas.offcanvas-end.show {
    background-color:#020705 !important; /* light gray background */
    color: white !important;
  }

  div[data-bs-popper="static"] {
    background: #020705 !important;
  }
  
}
/* Styles for medium screens */
@media (min-width: 600px) {
  body {
    font-size: 18px;
  }
}

/* Styles for large screens */
@media (min-width: 1200px) {
  body {
    font-size: 20px;
  }
}


@media (min-width: 641px) and (max-width: 1023px) {
  .dropdown-item:focus, .dropdown-item:hover {
    background-color:#020705 !important;
    }
  .offcanvas{
    --bs-offcanvas-width: 60% !important; 
  }
  .btn-close {
    --bs-btn-close-bg: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e) !important;
  }
	/* styles for large mobile screens */
	.dgColor {
		background:transparent !important;
		color: white !important;
	}

	.dgColor div {
		background-color: inherit;
	}

	/* a:hover {
		background: #020705 !important;
	} */

  .offcanvas.offcanvas-end.show {
    background-color:#020705 !important; /* light gray background */
    color: white !important;
  }

  div[data-bs-popper="static"] {
    background: #020705 !important;
  }

}

/* Large tablet screens */
@media (min-width: 768px) and (max-width: 1024px) {
  .dropdown-item:focus, .dropdown-item:hover {
    background-color:#020705 !important;
    }

  .offcanvas{
    --bs-offcanvas-width: 60% !important; 
  }
  .btn-close {
    --bs-btn-close-bg: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e) !important;
  }
	/* styles for large mobile screens */
	.dgColor {
		background: transparent !important;
		color: white !important;
	}

	.dgColor div {
		background-color: inherit;
	}

	/* a:hover {
		background: #020705 !important;
	} */

  .offcanvas.offcanvas-end.show {
    background-color:#020705 !important; /* light gray background */
    color: white !important;
  }

  div[data-bs-popper="static"] {
    background: #020705 !important;
  }
}

@media only screen and (min-width: 1920px) {

  #offcanvasNavbarDropdown-expand-lg {
		color: white;
	}
}

@media (min-width: 641px) and (max-width: 1023px) {
  #offcanvasNavbarDropdown-expand-lg {
		color: white;
	}

}

/* Large desktops */
@media only screen and (min-width: 1600px) and (max-width: 1920px) {

	#offcanvasNavbarDropdown-expand-lg {
		color: white;
	}
}

/* Medium desktops */
@media only screen and (min-width: 1280px) and (max-width: 1600px) {

  #offcanvasNavbarDropdown-expand-lg {
		color: white;
	}

}
.uioffer-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 4 columns */
  gap: 20px; /* spacing between items */
  text-align: center;
  justify-content: space-between;
  margin: auto 50px;
  overflow: hidden;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .uioffer-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    overflow: hidden;
  }
}

@media only screen and (max-width: 767px) {
  .uioffer-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    text-align: center;
  }
}

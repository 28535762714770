.about-conatiner {
  padding-top: 50px;
  color: white;
  text-align: center;
  height: 300px;
}
.about-conatiner h1 {
  font-weight: 900;
  font-size: 4rem;
  padding-top: 3rem;
}
.about-conatiner p {
  padding-left: 7rem;
  padding-right: 7rem;
}
.story-col {
  height: auto;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.story-images img {
  width: 150px;
  margin-top: 150px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.career-banner-text {
  color: white;
}

.ecombanner-section {
  height: 85vh;
  width: 100%;
  padding: 0;
  margin: 0;
  background: rgb(5, 17, 39);
  background: linear-gradient(
    9deg,
    rgba(5, 17, 39, 1) 0%,
    rgba(5, 14, 31, 1) 95%
  );
  position: relative;
}

.ecombanner-section-image img {
  width: 90%;
  height: 90%;
  padding: 0;
  margin: 0;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .ecombanner-section-content p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5; /* Limit to 20 lines */
    -webkit-box-orient: vertical;
  }
  .ecombanner-section {
    height: auto;
  }
}

@media only screen and (max-width: 767px) {
  .ecombanner-section-content {
    padding-top: 0px;
  }
  .ecombanner-section {
    height: auto;
  }
  .ecombanner-section-content p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5; /* Limit to 20 lines */
    -webkit-box-orient: vertical;
  }
}

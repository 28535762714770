.uiskills-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 4 columns */
  gap: 50px; /* spacing between items */
  text-align: center;
  justify-content: space-between;
  margin: auto 50px;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .uiskills-container {
    display: flex;
    flex-direction: column;

    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .uiskills-container {
    display: flex;
    flex-direction: column;

    text-align: center;
  }
}

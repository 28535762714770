.webdevbody-col1 
{
  background-image: line-height (blue,lightskyblue);
  height: fit-content;
  padding-top: 5%;
  text-align: justify;
  padding-bottom: 8%;
}

.webdevbody-col2 {
  height: 185px;
  text-align: justify;
  overflow: hidden;
}

.webdivbody-img {
  height: 100%;
}
.webdivbody-img img {
  width: 100%;
  padding: 0;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .webdevbody-col2 {
    height: 250px;
    overflow: hidden;
  }
  .webdevbody-col1 {
    height: 900px;
  }
}

@media only screen and (max-width: 767px) {
  .webdevbody-col2 {
    height: 250px;
    overflow: hidden;
  }
  element.style {
    font-size: 20px;
    font-family: gellix;
}
}

.banner-section {
  width: 100%;
  height: 85vh;
}
.banner-container img {
  width: 100%;
  padding: 0px;
  margin: 0px;
  height: 85vh;
  position: relative;
}
.banner-container-content {
  position: absolute;
  top: 20%;
  color: white;
  
}
.banner-container-content p {
  font-size: 1.5rem;
  color: white;
  
}
p.px-5.pb-3 {
  color: white;
}
@media (min-width: 1200px) {
  .h1, h1 {
      font-size: 2.5rem;
      color: white;
  }
  
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .banner-container-content {
    position: absolute;
    top: 20%;
    color: white;
  }
  .banner-container-content p {
    font-size: 1.2rem;
    color: white;
    
  }
}

@media only screen and (max-width: 767px) {
  .banner-container-content {
    position: absolute;
    top: 18%;
    color: white;
  }
  .banner-container-content p {
    font-size: 1rem;
    color: white;
  }
}
p.px-5.pb-3 {
  color: white
}


.mobilebanner-container {
  position: relative;
}
.mobilebanner-container img {
  width: 100%;
  margin: 0;
  padding: 0;
  height: 600px;
}

.mobilebanner-container-content {
  position: absolute;
  top: 25%;
  left: 3%;
  max-width: 50%;
}
/* .mobilebanner-container-content p {
  padding-left: 5%;
  padding-right: 50%;
  text-align: center;
}
.mobilebanner-button {
  width: 45%;
} */

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .mobilebanner-container-content {
    max-width: 80%;
    padding: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .mobilebanner-container-content {
    max-width: 100%;
    padding: 10px;
  }
}

.heading-container {
  text-align: center;
}
.heading-container h3 {
  font-size: 1.8rem;
  font-weight: 700;
}
.heading-container p {
  padding-left: 10%;
  padding-right: 10%;
}

.industry-container {
  background-image: url(../Assets/indbanner2.jpg);
  height: 600px;
  background-size: cover;
  background-position: center;
  /* opacity: 0.5; */
  position: relative;
}

.industry-content {
  padding-top: 100px;
  position: absolute;
  top: 10%;
  left: 0px;
}
.homeimage-content h1 {
  font-weight: 900;
  font-size: 4rem;
}
.industry-content p {
  padding-left: 15%;
  padding-right: 15%;
}

@media (max-width: 1024px) {
  .industry-content {
    padding-top: 20px;
  }
}

.hiring-content {
  height: 80vh;
}

.hiting-content img {
  width: 30%;
}
.hiring-text-content {
  text-align: justify;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 15; /* Limit to 20 lines */
  -webkit-box-orient: vertical;
}

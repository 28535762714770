.softdevbanner-container {
  position: relative;
  width: 100%;
}
.softdevbanner-container-video {
  width: 100%;
}

.softdevbanner-container-content {
  position: absolute;
  top: 25%;
  right: 5%;
  max-width: 50%;
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .softdevbanner-container-content {
    position: absolute;
    top: 15%;
    right: 5%;
    max-width: 50%;
  }
}



@media only screen and (max-width: 767px) {
  .softdevbanner-container-content {
    position: absolute;
    top: 10%;
    right: 5%;
    max-width: 50%;
  }
}
@media (min-width: 1200px) {
  .h1, h1 {
      font-size: 2.5rem;
      
  }
}

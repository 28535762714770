.ourskill-container img {
  padding: 0;
  padding-top: 10%;
  width: 80%;
}
.ourskill-container {
  height: 200px;
  overflow: hidden;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .ourskill-container {
    height: 250px;
    overflow: hidden;
  }
}

@media only screen and (max-width: 767px) {
  .ourskill-container {
    height: 350px;
    overflow: hidden;
  }
}

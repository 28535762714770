.hometech-container-text {
  padding-left: 10%;
  padding-right: 10%;
}

.hometech-col {
  height: 100vh;
  position: relative;
  overflow: hidden;
}
.hometech-link {
  position: absolute;
  bottom: 3%;
  left: 40%;
  text-align: center;
}
.knowmorelink {
  text-decoration: none;
}
.knowmorelink:hover {
  color: brown;
}

.hometech-text-content {
  padding: 3rem;
  text-align: center;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 15; /* Limit to 20 lines */
  -webkit-box-orient: vertical;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
}

/* Media query for mobile devices (portrait) */
@media only screen and (max-width: 850px) {
}
element.style {
  margin-bottom: auto;
}
p {
  margin-top: 0;
  margin-bottom: 3%;
}
p.hometech-text-content {
  padding: 0;
}
element.style {
  font-family: gellix;
  font-size: 20px;
}
.homeindustry-container p {
  padding-left: 15%;
  padding-right: 15%;
}

.homeindustry-container-col {
  height: 450px;
}
/* .homeindustry-container-img {
 
} */
.homeindustry-container-col-div:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.homeindustry-container-col-div {
  width: 100%;
  height: 90%;
  background-color: white;
}
.homeindustry-container-col-div p {
  font-size: 20px;
  font-family: gellix;
}

@media only screen and (max-width: 600px) {
  .homeindustry-container-col {
    height: 300px;
  }
}

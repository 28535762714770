.homeabout-img img {
  padding: 0;
  width: 80%;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  /* Your tablet styles here */
}

/* Media query for mobile devices (portrait) */
@media only screen and (max-width: 767px) {
}
.h5, h5 {
  font-size: 20px;
  font-family: Gellix;
}
.home-banner {
  padding: 0;
  margin: 0;
  height: 600px;
}

.banner-image {
  width: 100%;
  padding: 0;
  margin: 0;
  height: 600px;
}
.banner-container {
  padding: 0;
  margin: 0;
  width: 100%;
}

.banner-title {
  font-size: 4rem;
  text-align: center;
  padding-top: 8%;
}
.banner-description {
  font-size: 2rem;
  text-align: center;
}
p.hometech-text-content {
  font-family: gellix;
  font-size: 20px;
}



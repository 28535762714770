.contactbanner-section {
  width: 100%;
  height: 85vh;
}
.contactbanner-container img {
  width: 100%;
  padding: 0px;
  margin: 0px;
  height: 85vh;
  position: relative;
}
.contactbanner-container-content {
  position: absolute;
  top: 30%;
  color: white;
}

.uxbanner-container {
  position: relative;
}
.uxbanner-container img {
  width: 100%;
  margin: 0;
  padding: 0;
  
}
.uxbanner-container-content {
  position: absolute;
  top: 25%;

  color: white;
  width: 60%;
  left: 0;
  text-align: center;
}
.uxbanner-container img {
  height: 650px;
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .uxbanner-container-content {
    position: absolute;
    top: 15%;

    width: 60%;
    left: 0;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .uxbanner-container-content {
    position: absolute;
    top: 15%;

    width: 80%;
    left: 0;
    text-align: center;
  }
  .uxbanner-container img {
    height: 650px;
}
}
.softdevbanner-container {
  position: relative;
}
.softdevbanner-container img {
  width: 100%;
  margin: 0;
  padding: 0;
  height: 600px;
}

.softdevbanner-container-content {
  position: absolute;
  top: 25%;
  left: 5%;
  max-width: 50%;
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .softdevbanner-container-content {
    position: absolute;
    top: 15%;
    right: 5%;
    max-width: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .softdevbanner-container-content {
    position: absolute;
    top: 10%;
    right: 5%;
    max-width: 50%;
  }
}

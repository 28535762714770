.tech-container {
  background-image: url(../Assets/techbanner2.jpg);
  height: 600px;
  background-size: cover;
  position: relative;
}
.tech-content {
  position: absolute;
  width: 50%;
  top: 25%;
  left: 5%;
  height: 80%;
}

.tech-content h1 {
  font-weight: 700;
  font-size: 2.5rem;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .tech-content {
    position: absolute;
    width: 70%;
    top: 18%;
    left: 15%;
  }
  .tech-content h1 {
    font-size: 1.8rem;
  }
}

@media only screen and (max-width: 767px) {
  .tech-content {
    position: absolute;
    width: 80%;
    top: 15%;
    left: 5%;
  }
  .tech-content h1 {
    font-size: 1.5rem;

    text-align: center;
  }
}
.techbannertex{
  text-align:left;/*or left,or right*/
  
}
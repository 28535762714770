.techbody-col {
  height: 550px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 15; /* Limit to 20 lines */
  -webkit-box-orient: vertical;
}
img {
  width: 30%;
  padding: 1rem;
}
.text-content {
  height: 550px;
  padding: 1rem;
  text-align: justify;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 15; /* Limit to 20 lines */
  -webkit-box-orient: vertical;
}

.homecontact-container {
  position: relative;
}

.homecontact-container img {
  width: 100%;
  height: 350px;
  margin: 0;
  padding: 0;
}
.homecontact-container-content {
  position: absolute;
  top: 20%;
  left: 0;
  

  width: 100%;
}

@media only screen and (max-width: 767px) {
  .homecontact-container img {
    height: 600px;
  }
}
h5 {
  color: black;
}
p.px-5 {
  color: black;
}
element.style {
  color: black;
}
element.style {
    color: black;
}

.webdevbanner-container {
  position: relative;
}
.webdevbanner-container img {
  width: 100%;
  margin: 0;
  padding: 0;
  height: 600px;
}

.webdevbanner-container-content {
  position: absolute;
  top: 20%;
  left: 0;
}
.webdevbanner-container-content p {
  padding-left: 30%;
  padding-right: 30%;
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .webdevbanner-container-content p {
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media only screen and (max-width: 767px) {
  .webdevbanner-container-content p {
    padding-left: 5%;
    padding-right: 5%;
  }
  element.style {
    font-size: 20px;
    font-family: gellix;
}
}
